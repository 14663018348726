<template>
  <a-modal
    title="上传支付凭证"
    :visible="visible"
    :width="480"
    @cancel="handleCancel"
    @ok="confirm">
    <a-form-model
      class="bill-form"
      ref="billForm"
      label-align="left"
      layout="horizontal"
      :label-col="{span: 6}"
      :wrapper-col="{span: 18}"
      :model="form"
      :rules="rules">
      <a-form-model-item label="支付凭证" prop="bill">
        <form-upload :limit="9" v-model="form.bill"></form-upload>
      </a-form-model-item>
      <a-form-model-item label="备注">
        <a-textarea v-model="form.bill_note" :rows="4" placeholder="备注"></a-textarea>
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>

<script>

    export default {
        name: "BillModal",
        props: {
            fsId: Number, // 佛事id
            bill: Object,
            value: Boolean,
        },
        data() {
            return {
                form: {},
                rules: {
                    bill: [{required: true, message: '请上传支付凭证', trigger: 'change'}]
                }
            }
        },
        computed: {
            visible: {
                get() {
                    return this.value;
                },
                set(val) {
                    this.$emit("input", val)
                }
            }
        },
        watch: {
            bill() {
                this.setForm();
            }
        },
        created() {
            this.setForm();
        },
        methods: {
            setForm() {
                const bill = this.bill;
                if (bill) {
                    this.form = {
                        bill: bill.bill_img ? JSON.parse(bill.bill_img).join(",") : undefined,
                        bill_note: bill.bill_note
                    }
                } else {
                    this.form = {};
                }
            },
            handleCancel() {
                this.visible = false;
            },
            confirm() {
                this.$refs.billForm.validate(valid => {
                    if(valid) {
                        const form = this.form;
                        const data = {
                            bill_img: JSON.stringify(form.bill.split(","))
                        };
                        if(form.bill_note) {
                            data.bill_note = form.bill_note;
                        }
                        this.$axios({
                            url: `/admin/fs/${this.fsId}`,
                            method: 'PATCH',
                            data
                        }).then(res => {
                            this.$emit("update", res);
                        })
                    }
                })
            }
        }
    }
</script>

<style scoped>

</style>

<template>
    <div class="content md flex-box vertical">
        <div class="data-title">
            <div class="title-left flex-box">
                <span>{{temp ? `${temp.name}订单列表 ` : '' }}共{{pagination.total}}条记录</span>
            </div>
            <a-form-model class="query-form" layout="inline">
                <a-form-model-item label="">
                    <a-select
                            style="width: 180px"
                            placeholder="选择订单状态筛选"
                            @change="getList"
                            :options="statusList"
                            v-model="form.status">
                    </a-select>
                </a-form-model-item>
            </a-form-model>
            <a-space class="title-btn">
                <a-button @click="getList">查询</a-button>
                <a-button type="primary" @click="goAdd" v-if="temp">线下登记</a-button>
            </a-space>
        </div>
        <div class="data-area">
            <a-table
                    ref="dataTable"
                    :columns="columns"
                    row-key="id"
                    :data-source="list"
                    :loading="loading"
                    :pagination="pagination"
                    @change="handleTableChange">
                <template v-slot:action="text, record">
                    <div class="row-btn">
                        <a class="txt-btn" @click.stop="showDetail(record)" v-if="record.operate">{{ record.operate.title }}</a>
                        <a class="txt-btn" @click.stop="showDetail(record)" v-else>查看</a>
                        <a class="txt-btn" @click.stop="refundOrder(record)" v-if="record.status == 2">退款</a>
                        <a class="txt-btn" @click.stop="uploadBill(record)">上传支付凭证</a>
<!--                        <a class="txt-btn" @click.stop="claim(record)">认领</a>-->
                    </div>
                </template>
                <template v-slot:expandedRowRender="text">
                    <a-table
                      row-key="id"
                      :columns="innerColumns"
                      :data-source="text.tablet"
                      :pagination="false"
                      v-if="text.tablet && text.tablet.length > 0">
                    </a-table>
                </template>
            </a-table>
        </div>
        <a-modal
          title="退款"
          centered
          :maskClosable="false"
          :closable="false"
          :width="400"
          :ok-button-props="{props: {loading: refunding}}"
          :ok-text="refunding ? '退款中' : '退款'"
          @ok="confirmRefund"
          v-model="visible">
            <div class="flex-box align-center">
                <span>退款金额</span>
                <div class="flex-grow" style="margin-left: 10px;">
                    <a-input-number v-model="refundNum" :min="0.01" :max="refundMax" placeholder="请输入退款金额" style="width: 100%;"/>
                </div>
            </div>
        </a-modal>
        <bill-modal :bill="bill" v-model="bv" @update="getList" />
    </div>
</template>

<script>
    import {
        getBuddhismOrderStatusText,
        buddhismOrderStatus,
        getBuddhismOperateList
    } from "../../common/buddha/buddhism";
    import {
        getBuddhaSubTypeName,
        calculateBuddhismPrice,
        dealBuddhaTypeInfo,
        getTabletPrice,
        getBuddhismShowName
    } from "../../common/buddha/type";
    import {isEmpty, omit} from "../../common/js/tool";
    import BillModal from "../../layouts/buddha/BillModal";
    import {getTemple} from "../../common/js/storage";

    export default {
        name: "BuddhismOrder",
        components: {
            BillModal
        },
        props: {
            temp: Object
        },
        data() {
            return {
                loading: false,
                list: [],
                form: {},
                h: 500,
                statusList: [{key: -1, title: '全部'}].concat(buddhismOrderStatus),
                columns: [
                    {title: '订单号', dataIndex: 'order_num'},
                    {title: '下单时间', dataIndex: 'create_time'},
                    {title: '类别', dataIndex: 'tempName'},
                    {title: '订单金额', dataIndex: 'priceNum'},
                    {title: '订单状态', dataIndex: 'statusText'},
                    {title: '操作', key: 'action', width: 160, scopedSlots: { customRender: 'action'}}
                ],
                innerColumns: [
                    {title: '种类', dataIndex: 'subName'},
                    {title: '价格', dataIndex: 'price', customRender: text => `¥${(text / 100).toFixed(2)}`},
                ],
                pagination: {
                    total: 0,
                    current: 1,
                    pageSize: 10,
                    showSizeChanger: true
                },
                detail: null,
                visible: false,
                order: null,
                refundNum: 0,
                refundMax: 0,
                refunding: false,
                bv: false,
                bill: undefined,
            }
        },
        created() {
            this.getList();
        },
        mounted() {
            this.setHeight();
            window.addEventListener("resize", this.setHeight, false);
        },
        beforeDestroy() {
            window.removeEventListener("resize", this.setHeight, false);
        },
        methods: {
            goAdd() {
                this.$router.push({ name: 'BuddhismRegister', params: { id: this.temp?.id } })
            },
            uploadBill(item) {
                this.bill = {
                    bill_img: item.bill_img,
                    bill_note: item.bill_note
                }
                this.bv = true;
            },
            confirmRefund() {
                if(this.refundNum) {
                    this.refunding = true;
                    let url = '/admin/fs-order/refund';
                    let order = this.order;
                    this.$axios({
                        url,
                        method: 'POST',
                        data: {order_num: order.order_num, refund_fee: Math.round(this.refundNum * 100)}
                    }).then(res => {
                        if(res.error == 0) {
                            this.$message.success("退款成功");
                            this.getList();
                            this.visible = false;
                        } else {
                            this.$message.warning(res.msg);
                        }
                    }).finally(() => {
                        this.refunding = false;
                    })
                } else {
                    this.$message.warning('请输入退款金额');
                }
            },
            refundOrder(order) {
                this.refundMax = order.price / 100;
                this.refundNum = order.price / 100;
                this.order = order;
                this.visible = true;
            },
            showDetail(record) {
                this.$router.push({name: 'BuddhismDetail', params: {id: record.fs.id}});
            },
            handleTableChange(pagination) {
                let pager = { ...this.pagination };
                pager.current = pagination.current;
                pager.pageSize = pagination.pageSize;
                this.pagination = pager;
                this.getList();
            },
            getList() {
                if(this.loading) return;
                this.loading = true;
                let {current, pageSize} = this.pagination;
                let url = `/admin/fs-order?sort=-id&expand=fs.temp&cat=1,2&page=${current}&pageSize=${pageSize}&temple_id=${getTemple()}`;
                let form = this.form;
                if(!isEmpty(form.status) && form.status >= 0) {
                    url += `&filter[status]=${form.status}`;
                }
                if (this.temp) {
                    url += `&temp_id=${this.temp.id}`;
                }
                this.$axios(url, { noTempleFilter: true}).then(res => {
                    this.dealList(res.data);
                    this.$set(this.pagination, 'total', res.page.totalCount);
                }).finally(() => {
                    this.loading = false;
                });
            },
            dealList(list) {
                const btnTypeIndex = {
                    claim: 0,
                    audit: 1,
                    dispatch: 3,
                    reDispatch: 4,
                    open: 5,
                    reOpen: 6,
                }
                const types = Object.keys(btnTypeIndex);
                list.forEach(item => {
                    item.priceNum = '¥' + ((item.price || 0) / 100).toFixed(2);
                    item.statusText = getBuddhismOrderStatusText(item.status);
                    if (item.fs) {
                        item.tempName = getBuddhismShowName(item.fs);
                        const fs = {...item.fs};
                        fs.order = omit(item, ['fs']);
                        const btnList = getBuddhismOperateList(fs).filter(b => types.includes(b.type));
                        if (btnList.length > 0) {
                            btnList.sort((a, b) => (btnTypeIndex[a.type] || 100) - (btnTypeIndex[b.type] || 100));
                            item.operate = btnList[0];
                        }
                    } else {
                        item.tempName = "--"
                    }
                    // 有牌位信息
                    if(item.fs?.tablet_info) {
                        item.tablet = item.fs.tablet.map((t, i) => {
                            let res = {...t};
                            try {
                                let info = JSON.parse(t.info);
                                res = {...t, ...info};
                            } catch (e) {
                                console.error(e);
                            }
                            res.subName = item.fs && item.fs.temp ? getBuddhaSubTypeName(item.fs.temp.fs_type, res.type) || item.tempName : '--';
                            if(!res.price) {
                                res.price = getTabletPrice(res, i, item.fs.temp, item.fs.time_range) * 100;
                            }
                            // res.priceNum = item.fs && item.fs.temp ? '¥' + (getTabletPrice(res, index, item.fs.temp) / 100).toFixed(2) : '--';
                            return res;
                        });
                        if(item.fs.temp) {
                            dealBuddhaTypeInfo(item.fs.temp);
                        }
                        if(item.fs.detail) {
                            try {
                                let detail = JSON.parse(item.fs.detail);
                                for(let key in detail) {
                                    item.fs[key] = detail[key];
                                }
                            } catch (e) {
                                console.error(e)
                            }
                        }
                        const { masterPrice } = calculateBuddhismPrice(item.fs.temp, {
                            tabletInfo: item.fs.tablet,
                            dateTime: {range: item.fs.time_range},
                            number: item.fs.number,
                            bookType: item.fs.scripture ? 'scripture' : 'chanting'
                        });
                        if(masterPrice > 0) {
                            item.tablet.push({
                                subName: '多出法师价格',
                                price: masterPrice
                            })
                        }
                    } else {
                        // 无牌位信息
                        item.tablet = [
                            {subName: item.tempName, price: item.price, id: item.id}
                        ]
                    }
                });
                this.list = list;
            },
            setHeight() {
                let dataTale = this.$refs.dataTable;
                if(dataTale) {
                    let area = this.$el.querySelector(".data-area");
                    this.h = area.clientHeight - 54 - 64;
                }
            },
        }
    }
</script>

<style scoped>

</style>
